@media (min-width: 1400px) {
	.services__details {
		justify-content: center;

		&-item {
			margin: 0 20px;
		}
	}
}

@media (hover: hover) {
	.rev_slider_wrapper {

		.tp-bullet:hover {
			background: #fff;
		}
	}

	.header {
		&.active .header__button--menu:hover {
			i {
				background: $main-theme-color;
			}
		}

		&__button--menu:hover {
			i:nth-of-type(3) {
				width: 100%;
			}
		}
	}

	.info-text a:hover {
		color: #103640;
	}

	.promotions {

		&__image:hover {
			&:before {
				background-color: rgba(0, 0, 0, 0.25);
			}
			
			img {
				transform: scale(1.1);
			}
		}

		&__title:hover {
			color: #7a756c;
		}

		&__link:hover {
			color: #7a756c;
		}
	}

	.services__image:hover {
		&:before {
			background-color: rgba(0, 0, 0, 0.25);
		}

		img {
			transform: scale(1.1);
		}
	}

	.gallery__image a:hover {
		&:before {
			background-color: rgba(0, 0, 0, 0.25);
		}
		
		img {
			transform: scale(1.1);
		}
	}

	.blog {

		&__image img:hover {
			transform: scale(1.1);
		}
		
		&__category a:hover {
			color: #7a756c;
		}

		&__item-title:hover {
			color: #7a756c;
		}

		&__link:hover {
			color: #7a756c;
		}
		
	}

	.footer {

		&__navigation-item:hover {
			color: $footer-text-hover-color;
		}

		&__social-item:hover svg {
			fill: #50585c;
		}

		&__gotop:hover svg {
			fill: #50585c;
		}

		&__allrights a:hover {
			color: #50585c;
			text-decoration: underline;
		}
	}

	.menu-language__item:hover {
		border-color: #fff;
		color: #50585c;
	}

	.main-nav {

		&__hidden-menu__item:hover {
			color: $header-menu-item-active-color;
		}

		&__label:hover {
			color: $header-menu-item-active-color;

			i:before,
			i:after {
				background-color: #244356;
			}
		}
	}

	.products {

		&__image:hover img {
			transform: scale(1.1);
		}

		&__title:hover {
			color: #7a756c;
		}
	}

	.nav-years__item:hover {
		color: #274451;
	}

	.filters__buttons-item:hover {
		background-color: #716e69;
		border-color: #716e69;
	}

	.slick-dots > li:hover {
		background: #c2bfba;
	}

	.breadcrumbs__item:hover {
		color: #2c2923;
	}

	.btn:hover {
		background-color: #ada9a3;
		color: #fff;
	}

	.banner__blur-btn:hover {
		background-color: #fff;
		color: #274451;
	}

	.pdp-characteristics__btn:hover {
		background-color: #716e69;
		border-color: #716e69;
	}
	 
	.inner-info__btn:hover {
		color: #fff;
		background-color: #716e69;
		border-color: #716e69;
	}
}

@media (max-width: map-get($grid-breakpoints, xxl)) {

	.promotions {
		&__image {
			width: 55%;
		}
		
		&__info {
			width: 45%;
			padding: 0 60px;
		}
	}
}

@media (max-width: map-get($grid-breakpoints, xl)) {

	.blog__info {
		padding: 30px;
	}

	.header__menu {
		
		&-logo {
			min-width: 100px;
			margin-right: 35px;
		}

		&-social-item {
			margin: 10px;
		}
	}

	.promotions {
		&__caption {
			font-size: 3.8rem;
			bottom: 50px;
		}
		
		&__title {
			font-size: 2.5rem;
		}
	}

	.info-section {
		padding: 100px 0;

		&__title {
			font-size: 4.5rem;;
		}
	}

	.products__item {
		padding: 0 30px;
	}

	.filters__item {
		flex-basis: 33.33%;
	}

	.gallery__caption {
		font-size: 1.2rem;
	}

	.pdp-characteristics {

		&__title {
			font-size: 3rem;
		}

		&__price {
			font-size: 2rem;
		}

		&__value {
			font-size: 1.5rem;
		}
	}

	.pdp-title {
		font-size: 3.8rem;
	}

	.banner__blur-btn {
		font-size: 3rem;
	}
}

@media (max-width: map-get($grid-breakpoints, tablet)) {
	html {
		font-size: 15px;
	}

	body.noScroll {

		.header__inner {
			padding-right: 65px;
		}

 		.header.fixed .header__inner {
			padding-right: 20px;
		}
	}

	.header {

		&__menu-top {
			padding-top: 10px;
			padding-bottom: 10px;
		}
	}

	.main-nav {

		&--secondary .main-nav__label {
			font-size: 1.5rem;
		}
		
		&__label {
			font-size: 1.8rem;
		}

		&__expand {
			min-width: 20px;
			height: 20px;

			&:before {
				height: 2px;
			}

			&:after {
				width: 2px;
			}
		}

		&__hidden-menu__item {
			font-size: 1.2rem;
		}
	}

	.border-rectangle--small,
	.border-rectangle--medium {
		width: calc(100% - 90px);
		height: calc(100% - 90px);
		top: 45px;
		left: 45px;
	}

	.rev_slider_wrapper {
		.tp-bullets {
			left: calc(100% + 25px) !important;
		}
		
		.border-rectangle--small {
			top: 65px;
			height: calc(100% - 130px);
		}
	}

	.promotions {

		&__info {
			padding-left: 3rem;
			padding-right: 3rem;
		}
	}

	.services {

		&__info {
			padding-right: 80px;
		}

		&__caption {
			font-size: 1.85rem;
			bottom: 60px;
		}
	}

	.info-section {
		padding: 80px 0;

		&__title {
			font-size: 4rem;;
		}

		&__text {
			margin: 25px auto 10px auto;
		}
	}

	.products {
		padding: 0 20px 40px 20px;

		&__item {
			margin-top: 100px;

			&:last-child {
				margin-bottom: 60px;
			}
		}

		&__title {
			font-size: 1.8rem;
		}

		&__text {
			padding-right: 0;
		}
	}

	.filters {

		&__title {
			font-size: 3.2rem;
		}

		&__item-select {
			padding: 15px;
			font-size: 1.1rem;
		}

		&__buttons-item {
			padding: 15px;
			font-size: 1.3rem;
		}
	}

	.slick-dots > li {
		margin: 10px;
	}

	.gallery__caption {
		bottom: 60px;
	}




	 

	.blog {
		margin-top: 60px;
	}

	.form {

		&__input,
		&__textarea {
			-webkit-appearance: none;
		}
	}
}

@media (max-width: map-get($grid-breakpoints, lg)) {
	.wrapper {
		padding-left: 20px;
		padding-right: 20px;
	}

	body.noScroll .header__inner {
		padding-right: 20px;
	}

	.header {

		&.fixed {
			.header__inner {
				padding: 10px 20px;
			}
		} 

		&__menu-social {
			display: none;
		}

		&__inner {
			padding: 10px 20px;
		}

		&__logo {
			height: 33px;
			width: 130px;
		}

		&__menu {
			background: #fff;

			&-top {
				padding: 10px 25px;

				&-left {
					width: 100px;
				}

				&-right {
					width: calc(100% - 100px);
				}
			}

			&-logo {
				width: 75px;
				height: 35px;
				min-width: 30px;
				flex-basis: 75px;
				margin-right: 15px;

				&-icon {
					width: 70px;
				}
			}

			&-language {
				padding-inline-start: 0;
			}

			&-close {
				width: 20px;
				height: 20px;
			}
		}

		&__menu-nav {
			flex-direction: column;
		}
	}

	.rev_slider_wrapper__scroll-down {
		bottom: 120px;
	}

	.menu-language__item {
		font-size: 16px;
		margin: 5px;
		padding: 0;
		margin: 10px 15px;
		color: #908c85;
	}

	.main-nav {
		width: 100%;
		padding-block-start: 25px;
		padding-inline-end: 25px;
		padding-inline-start: 25px;
		padding-bottom: 50px;

		&--secondary {
			background-color: #c2bfba;

			.main-nav {
				&__label {
					font-size: 1.4rem;
				}
			}
		}

		&__item {
			margin-top: 30px;
		}

		&__label {
			font-size: 1.4rem;
		}

		&__expand {
			min-width: 20px;
			height: 20px;
		}

		&__hidden-menu {
			padding-left: 30px;

			&__item {
				font-size: 14px;
				margin: 20px 20px 0 0
			}
		}
	}

	.rev_slider_wrapper {

		&--innerpage .slotholder {
			height: 100vh;
			min-height: 500px;
		}


		.tp-bullets {
			top: auto !important;
			left: 50% !important;
			bottom: 30px;
			transform: rotate(-90deg) translateX(-50%) !important;
			margin-left: -3px;
		}
		
		.tp-bullet {
			transform: rotate(90deg);
		}

		.main_caption_name {
			font-size: 28px !important;
			line-height: 1.3 !important;

			&--subtitle {
				font-size: 20px !important;
			}
		}
	} 

	.welcome {
		padding: 0;
		margin: 0;
		
		&__container {
			padding: 40px 0px;
		}

		&__logo {
			width: 140px;
			height: 140px;
			margin-left: auto;
			margin-right: auto;
		}

		&__text {
			margin: 0;
			max-width: 100%;
		}
	}

	.advertise-logos {
		margin: 30px 0;
	}

	.promotions {
		padding: 0;

		&__item {
			flex-direction: column;
			margin-top: 60px;

			&:last-child {
				margin-bottom: 60px;
			}

			&--type2 .promotions__info {
				order: 3;
			}
		}

		&__image {
			width: 100%;
			margin: 0;
			z-index: 2;
		}

		&__caption {
			font-size: 3rem;
		}

		&__info {
			width: 100%;
			padding: 40px 20px;
			margin: 0;
		}

		&__bottom {
			margin-top: 30px;
		}
		
		&__title {
			display: inline-block;
			font-size: 2rem;
		}

		&__description {
			font-size: 1rem;
		}

		&__price {
			font-size: 1.25rem;
		}
	}

	.banner {
		margin: 0;

		&__container {
			padding: 45px 0;
		}

		&__pattern {
			width: 200px;
			height: 200px;
			left: 0;
			right: 0;
			margin: auto;
		}

		&__shape {
			width: 60px;
			height: 60px;
		}

		&__title {
			font-size: 2rem;

			span {
				font-size: 6rem;
			}
		}

		&__subtitle {
			font-size: 2rem;
			padding: 30px 0 45px;
		}

		&__description {
			padding: 30px 0 45px !important;
			font-size: 1rem;
		}
	}

	.main-paralax {
		height: 550px;
		padding: 20px;

		&--big {
			height: 600px;
		}
	}

	.services {
		flex-direction: column;
		align-items: center;
		justify-content: center;

		&__item {
			width: 100%;
		}

		&__info {
			padding: 40px 20px;
		}

		&__details {
			padding: 20px 20px 80px 20px;
		}

		&__caption {
			bottom: 70px;
		}
	}

	.blog {
		padding: 40px 20px;
		margin: 0;
		overflow: hidden;
		$this: &;

		&--no-carousel {
			#{$this}__item {
				flex-basis: 50%;
			}
		}

		&__info {
			padding: 20px 0;
		}

		&__shape {
			top: 15px;
			right: -80px;
			width: 160px;
			height: 160px;
		}

		&__title {
			font-size: 38px;
			padding-right: 0;
		}

		&__category {
			padding-right: 0;
		}

		&__item-title {
			font-size: 2rem;
		}

		&__link {
			margin-inline-end: 35px;
		}
	}

	.footer {
		padding: 0;
		margin-top: 40px;

		&__logo {
			max-width: 180px;
			height: 140px;
		}

		&__map {
			padding: 0 20px 20px 20px;
		}

		&__info {
			font-size: 1rem;
		}

		&__logos {
			margin-bottom: 0;
		}

		&__navigation {
			margin-top: 20px;

			&-item {
				font-size: 1rem;
				margin: 10px 15px;
			}
		}

		&__social {
			
			&-item {
				width: 30px;
				height: 30px;
			}
		}

		&__allrights {
			padding: 20px;
			font-size: 1rem;
		}

		&__gotop {
			width: 13px;
			height: 26px;
			bottom: 20px;
		}
	}

	.info-section {
		padding: 60px 0;

		&__title {
			font-size: 3rem;
		}
	}

	.products {
		&__title {
			margin-top: 40px;
			font-size: 1.6rem;
		}

		&__text {
			margin-top: 15px;
		}
	}

	.filters {
		margin: 0 40px 40px;
		background-color: #c2bfba;
		padding: 60px 30px;
	}

	.nav-years {
		margin-top: 40px;

		&__item {
			font-size: 1.8rem;
		}
	}

	.gallery {
		
		&__image {
			padding: 20px;
		}
	}

	.btn {
		width: 100%;
		text-align: center;
		padding: 20px;
	}

	.contacts {
		padding: 50px 0;
		
		&__title {
			font-size: 2.5rem;
		}

		&__form {
			margin-top: 50px;
		}
	}

	.form__row .btn {
		margin-top: 50px;
	}

	.form__input {
		font-size: 1.3rem;
	}

	.breadcrumbs {
		padding: 20px 10px;
	}

	.pdp-characteristics {
		padding: 0;
	}

	.pdp-carousel .slick-dots {
		margin-bottom: 80px;
	}

	.pdp-title {
		font-size: 3rem;
	}
}

@media (max-width: map-get($grid-breakpoints, md)) {

	.rev_slider_wrapper {

		.tp-caption {
			min-width: 300px !important;
		}
	}

	.header {

		&__logo {
			min-width: 30px;
		}

		&__top-nav--main {
			display: none;
		}

		&__top-nav--language {
			margin-inline-start: 15px;
		}
	}

	.info-text {
		padding: 0;
	}

	.border-rectangle,
	.border-rectangle--small,
	.border-rectangle--medium {
		left: 28px;
		top: 28px;
		width: calc(100% - 50px);
		height: calc(100% - 50px);
	}

	.breadcrumbs__item {
		margin: 5px 0;
	}

	.banner {

		&__inner-shape {
			width: 30px;
			height: 30px;
		}

		&__title {
			padding: 0 20px;

			span {
				font-size: 4rem;
			}
		}

		&__blur {

			&-icon {
				width: 60px;
				height: 60px;
			}

			&-btn {
				padding: 15px 25px;
				font-size: 1.8rem;
			}
		}
	}

	.welcome--inner-page .welcome__text {
		margin-top: 25px;
	}

	.blog {
		$this: &;

		&--no-carousel {
			#{$this}__item {
				flex-basis: 100%;
				margin-bottom: 70px;

				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}

	.services {

		&__info {
			margin-bottom: 60px;
		}

		&__caption {
			bottom: 50px;
			font-size: 1.5rem;

			&::first-letter {
				text-transform: uppercase;
			}
		}
	}

	.blog {
		&__holder {
			margin-left: 0;
			margin-right: 0;
		}
	
		&__item {
			padding: 0;
			margin-bottom: 30px;
		}
	}

	.advertise-logos {
		flex-flow: column;
		justify-content: center;

		&__item {
			margin: 30px 15px;
		}
	}

	.promotions {

		&__caption {
			font-size: 2rem;
			bottom: 35px;
		}

		&__title {
			line-height: 1.2;
		}

		&__description {
			margin-top: 15px;
		}
		
		&__link {
			font-size: 1rem;
			margin-inline-end: 30px;
		}
	}

	.footer {

		&__logo {
			max-width: 150px;
			height: 110px;
			margin-top: -20px;
		}

		&__info {
			font-size: 14px;
			padding: 0 20px;
		}

		&__social {
			justify-content: center;
			margin-top: 40px;

			&-item {
				width: 25px;
				height: 25px;
				margin: 10px 15px;
			}
		}

		&__allrights {
			position: relative;
			padding-inline-start: 35px;
			padding-inline-end: 35px;
		}

		&__gotop {
			bottom: auto;
			top: 50%;
			transform: translateY(-50%);
			right: 10px;
		}
	}

	.info-section {
		&__shape {
			width: 40px;
			height: 40px;
		}
	}

	.products {

		&__item {
			width: 100%;
			padding: 0;
		}
	}

	.filters {
		margin: 0 20px 40px 20px;
		background-color: #c2bfba;
		padding: 40px 10px;

		&__title {
			font-size: 2.5rem;
		}

		&__item {
			flex-basis: 50%;
			min-width: 150px;
			padding: 0 10px;
			margin-top: 35px;

			&-select {
				font-size: 1rem;
			}

			&-label {
				font-size: 1rem;
			}
		}

		&__buttons {
			margin-top: 40px;

			&-item {
				padding: 10px;
				font-size: 1.2rem;
				margin: 15px;
			}
		}
	}

	.nav-years__item {
		font-size: 1.4rem;
		margin: 10px 15px;
	}

	.gallery {
		padding-left: 0;
		padding-right: 0;

		&__image {
			flex-basis: 100%;
		}

		&__caption {
			bottom: 35px;
		}
	}

	.form {

		&__row {
			margin-top: 25px;
			
			&--two-columns {
				display: block;
			}

			&--buttons {
				padding: 0;
				width: 100%;
				text-align: left;
				
				.form__col {
					display: block;
				}
			}

			.btn, .gcaptcha {
				margin-top: 20px;
			}
		}

		&__col {
			padding: 0;

			&-50 {
				width: 100%;

				&+& {
					margin-top: 25px;
				}
			}
		}

		&__input {
			font-size: 14px;
		}

		&__textarea {
			font-size: 14px;
			padding: 20px;
		}
	}

	.btn {
		font-size: 26px;
		padding: 10px 20px;
	}

	.slick-dots {
		margin-bottom: 40px;

		> li {
			width: 15px;
			height: 15px;
		}
	}

	.pdp-characteristics {

		&__btn {
			width: 100%;
			padding: 15px;
		}

		&__title {
			margin-top: 50px;
			font-size: 2rem;
		}

		&__price {
			font-size: 1.8rem;;
		}

		&__table {
			display: block;
			overflow-y: scroll;

			td {
				padding: 10px 10px 20px 10px;
			}

		}

		&__value {
			font-size: 1.2rem;
		}
	}

	.pdp-description {
		margin-top: 50px;
	}

	.pdp-gallery {
		margin-top: 50px;

		&__holder {
			margin-top: 30px;
		}
	}

	.pdp-title {
		font-size: 2rem;

		&__icon {
			margin: 0 25px;
			width: 30px;
			height: 30px;
		}
	}

	.pdp-text {
		margin-top: 50px;

		h1,h2,h3,h4,h5,h6 {
			font-size: 2rem;
		}
	}

	.pdp-carousel {
		margin-top: 70px;
	}

	.inner-info {

		&__title {
			font-size: 30px;
			margin-top: 40px;
		}

		&__price {
			font-size: 22px;
		}

		&__date {
			margin-top: 35px;
			font-size: 22px;
		}

		.pdp-description {
			margin-top: 25px;
		}
	}
}

@media (max-width: map-get($grid-breakpoints, sm)) {
	.filters {
		&__title {
			font-size: 1.8rem;
		} 

		&__item {
			flex-basis: 100%;
			min-width: 150px;
			margin-top: 20px;
		}

		&__item-select {
			padding: 10px 15px;
		}

		&__buttons {
			margin-top: 20px;
		}

		&__buttons-item {
			font-size: 1.1rem;
		}
	}

	.blog__item {
		margin-bottom: 20px;
	}

	.form {

		&__row {
			&--buttons {

				.form__col {
					flex-wrap: wrap;
				}
			}
		}

		&__checkbox + &__label {
			margin-left: 0;
		}
	}
}