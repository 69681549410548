@charset "UTF-8";
/* http://meyerweb.com/eric/tools/css/reset/
   v4.0 | 20180602
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
main, menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, main, menu, nav, section {
  display: block; }

/* HTML5 hidden-attribute fix for newer browsers */
*[hidden] {
  display: none; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

* {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
  -webkit-text-size-adjust: 100%;
  transition: all .2s ease;
  box-sizing: border-box; }

html.noScroll, body.noScroll {
  position: relative;
  overflow: hidden; }

html {
  height: 100%;
  font-size: 16px; }

body {
  color: #5a5753;
  line-height: 1.3;
  font-family: "Mada", sans-serif; }
  body.noScroll .header__inner {
    padding-right: 79px; }
  body.noScroll .header.fixed .header__inner {
    padding-right: 40px; }

a {
  display: inline-block;
  color: inherit;
  text-decoration: none; }

img {
  max-width: 100%; }

svg {
  max-width: 100%;
  max-height: 100%; }

.wrapper {
  width: 100%;
  max-width: 1140px;
  margin: 0 auto;
  padding-left: 15px;
  padding-right: 15px; }

.border-rectangle {
  position: absolute;
  width: calc(100% - 90px);
  height: calc(100% - 90px);
  top: 45px;
  left: 45px;
  pointer-events: none;
  z-index: 10; }
  .border-rectangle--medium {
    width: calc(100% - 130px);
    height: calc(100% - 130px);
    top: 65px;
    left: 65px; }
  .border-rectangle--small {
    width: calc(100% - 160px);
    height: calc(100% - 160px);
    top: 80px;
    left: 80px; }
  .border-rectangle:before, .border-rectangle:after {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    bottom: 0;
    right: 0;
    perspective: 1000;
    transform: translateZ(0);
    border: 2px solid rgba(11, 12, 16, 0);
    transition-delay: 1s; }
  .border-rectangle.active:before {
    width: 100%;
    height: 100%;
    border-bottom-color: #fff;
    border-left-color: #fff;
    transition: width .7s ease-out .7s,height .7s ease-out 1.4s; }
  .border-rectangle.active:after {
    width: 100%;
    height: 100%;
    border-right-color: #fff;
    border-top-color: #fff;
    transition: height .7s ease-out .7s,width .7s ease-out 1.4s; }

.hamburger-icon {
  width: 32px;
  height: 16px;
  cursor: pointer;
  display: flex;
  flex-flow: column;
  margin-inline-start: 25px; }
  .hamburger-icon i {
    height: 2px;
    width: 100%;
    background: #fff;
    margin-bottom: 5px;
    display: block; }
    .hamburger-icon i:nth-child(3) {
      width: 65%; }

.advertise-logos {
  display: flex;
  align-items: center;
  justify-content: center; }
  .advertise-logos__item {
    margin: 0 45px; }

.info-text {
  font-size: 1.25rem;
  line-height: 1.6;
  text-align: center;
  margin: 40px auto 10px auto;
  padding: 0 80px;
  color: #fff; }
  .info-text a {
    color: #50585c; }

@keyframes sdb {
  0% {
    opacity: 0; }
  30% {
    opacity: 1; }
  60% {
    box-shadow: 0 0 0 60px rgba(255, 255, 255, 0.1);
    opacity: 0; }
  100% {
    opacity: 0; } }

#sliderMain {
  min-height: 1080px; }
  #sliderMain * {
    transition: none; }

.rev_slider_wrapper {
  position: relative;
  z-index: 10; }
  .rev_slider_wrapper .slotholder {
    position: relative; }
    .rev_slider_wrapper .slotholder:before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.25); }
  .rev_slider_wrapper .main_caption_name {
    position: relative;
    font-family: "Mada", sans-serif;
    font-size: 4.375rem;
    font-weight: 300;
    text-transform: uppercase;
    line-height: 1.1;
    color: #fff; }
    .rev_slider_wrapper .main_caption_name--subtitle {
      font-size: 2rem; }
  .rev_slider_wrapper__text-layer {
    color: #fff !important;
    text-transform: uppercase; }
  .rev_slider_wrapper__scroll-down {
    position: absolute;
    bottom: 150px;
    left: 50%;
    margin: auto;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    cursor: pointer;
    transform: translate(0, -50%);
    transition: opacity 0.5s;
    z-index: 20; }
    .rev_slider_wrapper__scroll-down:after {
      position: absolute;
      content: '';
      width: 16px;
      height: 16px;
      margin-top: -5px;
      border-left: 1px solid #fff;
      border-bottom: 1px solid #fff;
      transform: rotate(-45deg);
      box-sizing: border-box; }
    .rev_slider_wrapper__scroll-down:before {
      position: absolute;
      z-index: -1;
      content: '';
      width: 44px;
      height: 44px;
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.1);
      border-radius: 100%;
      opacity: 0;
      animation: sdb 3s infinite;
      box-sizing: border-box; }
  .rev_slider_wrapper .tp-caption {
    white-space: inherit !important;
    text-align: center !important; }
  .rev_slider_wrapper .tp-bullets {
    left: calc(100% + 15px) !important; }
  .rev_slider_wrapper .tp-bullet {
    border-radius: 50%;
    width: 12px;
    height: 12px; }
    .rev_slider_wrapper .tp-bullet:after {
      display: none; }
    .rev_slider_wrapper .tp-bullet.selected {
      background: #fff; }
  .rev_slider_wrapper--innerpage .slotholder {
    height: 100vh;
    background-size: cover; }
  .rev_slider_wrapper--innerpage .border-rectangle {
    display: flex;
    align-items: center;
    justify-content: center; }
  .rev_slider_wrapper--innerpage .tp-bullets {
    display: none !important; }
  .rev_slider_wrapper--small .slotholder {
    height: 605px; }

.breadcrumbs {
  position: relative;
  padding: 30px 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center; }
  .breadcrumbs__item {
    position: relative;
    font-family: "Mada", sans-serif;
    font-size: 1rem;
    color: #736f69;
    padding: 0 8px;
    z-index: 2; }
    .breadcrumbs__item:last-child:after {
      display: none; }
    .breadcrumbs__item:after {
      position: relative;
      content: ' »';
      right: -4px; }

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 20; }
  .header.fixed {
    background-color: #918d88; }
    .header.fixed .header__logo {
      max-width: 70px; }
    .header.fixed .header__inner {
      padding-top: 12px;
      padding-bottom: 12px; }
    .header.fixed .header__top-nav--main {
      display: none; }
  .header.active .header__inner {
    display: none; }
  .header.active .header__button {
    background: #f0f3f4; }
    .header.active .header__button i {
      background: #185478; }
    .header.active .header__button span {
      opacity: 0; }
  .header.fixed.active .header__menu-top {
    padding-top: 10px; }
  .header__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 30px;
    transition: none; }
  .header__top-nav {
    display: flex;
    align-items: center;
    margin-inline-start: auto; }
    .header__top-nav--language {
      margin-inline-start: 35px;
      margin-inline-end: auto; }
  .header__top-nav-link {
    color: #fff;
    font-size: 1.125rem;
    text-transform: uppercase;
    margin: 0 15px;
    position: relative; }
    .header__top-nav-link:after {
      position: absolute;
      content: '';
      width: 100%;
      height: 2px;
      bottom: -2px;
      left: 0;
      background-color: #fff;
      transition: all .3s ease-in-out;
      opacity: 0; }
    @media (hover: hover) {
      .header__top-nav-link:hover:after {
        opacity: 1; } }
    .header__top-nav-link.active {
      font-weight: 700; }
  .header__logo {
    max-width: 80px;
    height: 30px; }
    .header__logo__icon {
      width: 100%;
      fill: #fff; }
  .header__button {
    position: relative;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 21; }
  .header__menu {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    background: linear-gradient(to right, white 0%, white 55%, #c2bfba 55%, #c2bfba 100%);
    visibility: hidden;
    overflow-y: auto;
    opacity: 0;
    z-index: 20; }
    .header__menu.active {
      opacity: 1;
      visibility: visible; }
      .header__menu.active .main-nav__item {
        opacity: 1; }
    .header__menu-top {
      display: flex;
      align-items: center;
      padding: 40px 40px; }
      .header__menu-top-left {
        width: 55%;
        display: flex;
        align-items: center; }
      .header__menu-top-right {
        width: 44%;
        display: flex;
        align-items: center; }
    .header__menu-logo {
      min-width: 125px;
      flex-basis: 125px;
      height: 65px;
      margin-right: 60px; }
      .header__menu-logo-icon {
        fill: #a4a09a; }
    .header__menu-social-item {
      width: 27px;
      height: 27px;
      margin: 10px 25px; }
      .header__menu-social-item svg {
        fill: #a4a09a; }
    .header__menu-language {
      padding-inline-start: 120px; }
    .header__menu-close {
      min-width: 20px;
      width: 25px;
      height: 25px;
      fill: #777777;
      cursor: pointer;
      margin-inline-start: auto; }
    .header__menu-nav {
      display: flex;
      justify-content: center; }

.welcome {
  margin-top: 80px;
  padding: 0 80px; }
  .welcome--inner-page {
    margin-top: 0; }
    .welcome--inner-page .info-section__shape {
      margin-bottom: 30px; }
  .welcome__container {
    padding: 50px 60px;
    text-align: center;
    background-color: #c2bfba; }
  .welcome__logo {
    max-width: 280px;
    margin: auto; }
    .welcome__logo svg {
      fill: #fff; }
  .welcome__text {
    font-size: 1.25rem;
    line-height: 1.6;
    margin: 40px auto 10px auto;
    color: #fff; }
    .welcome__text a {
      color: #50585c; }
  .welcome__logos {
    margin: 60px 0; }

.promotions__item {
  display: flex;
  align-items: center;
  margin-top: 80px; }
  .promotions__item--type2 .promotions__image {
    order: 2; }

.promotions__image {
  position: relative;
  width: 60%;
  position: relative;
  overflow: hidden; }
  .promotions__image:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    transition: all .3s ease-in-out;
    z-index: 1; }
  .promotions__image img {
    display: block;
    width: 100%;
    transition: all .4s ease-in-out; }
  .promotions__image a {
    display: block; }

.promotions__image-link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2; }

.promotions__caption {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 65px;
  font-size: 4.375rem;
  font-weight: 300;
  text-align: center;
  color: #fff;
  z-index: 1; }

.promotions__info {
  width: 40%;
  padding: 0 80px; }

.promotions__title {
  font-size: 2.875rem;
  line-height: 1.1;
  font-weight: 300;
  text-transform: uppercase;
  animation-delay: 0.3s;
  color: #274451; }

.promotions__description {
  font-size: 1.125rem;
  line-height: 1.55;
  margin-top: 30px;
  animation-delay: 0.5s; }

.promotions__bottom {
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  animation-delay: 0.7s; }

.promotions__price {
  color: #274451;
  font-weight: 300;
  font-size: 1.75rem; }

.promotions__link {
  display: flex;
  align-items: center;
  font-size: 1.25rem;
  font-weight: 700;
  color: #ada9a2;
  margin-inline-end: 25px; }

.promotions__seemore-icon {
  width: 50px;
  height: 20px;
  margin-inline-end: 10px; }

.banner {
  position: relative;
  margin-top: 80px;
  background-color: #c2bfba;
  font-weight: 300; }
  .banner--no-padding {
    padding: 0; }
  .banner--no-margin {
    margin: 0; }
  .banner--light {
    background-color: #dcdad8; }
  .banner--blur {
    overflow: hidden; }
    .banner--blur .banner__image {
      filter: blur(9px); }
  .banner--no-text .banner__pattern--bottom {
    top: auto;
    bottom: 0; }
  .banner__container {
    position: relative;
    padding: 80px; }
  .banner__pattern {
    position: absolute;
    width: 385px;
    height: 385px;
    fill: #cfccc6; }
    .banner__pattern--top {
      top: 0;
      left: 0; }
    .banner__pattern--bottom {
      right: 0;
      bottom: 0; }
  .banner__image {
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center; }
  .banner__text {
    text-align: center;
    z-index: 1; }
  .banner__title {
    font-size: 4.375rem;
    color: #fff;
    line-height: 1.15; }
    .banner__title span {
      display: block;
      font-size: 10rem;
      text-transform: uppercase; }
  .banner__subtitle {
    text-align: center;
    font-size: 3.125rem;
    color: #50585c;
    padding: 50px 0 80px; }
  .banner__description {
    margin: 0;
    padding: 50px 100px 80px 100px;
    font-weight: 400; }
  .banner__shape {
    width: 110px;
    height: 115px;
    fill: #fff; }
  .banner__inner-shape {
    position: absolute;
    width: 50px;
    height: 50px;
    fill: #fff; }
    .banner__inner-shape--top {
      top: 10px;
      left: 10px; }
    .banner__inner-shape--right {
      top: 10px;
      right: 14px; }
    .banner__inner-shape--bottom {
      bottom: 14px;
      right: 14px; }
    .banner__inner-shape--left {
      bottom: 14px;
      left: 12px; }
  .banner__blur-info {
    position: absolute;
    width: 100%;
    top: 50%;
    text-align: center;
    transform: translateY(-50%);
    z-index: 2; }
  .banner__blur-icon {
    width: 110px;
    height: 110px;
    fill: #fff; }
  .banner__blur-btn {
    font-size: 4rem;
    font-weight: 400;
    border: 2px solid #fff;
    color: #fff;
    padding: 45px 110px;
    text-transform: uppercase;
    margin-top: 50px; }
  .banner__bottom {
    background-color: #c2bfba; }

.main-paralax {
  position: relative;
  height: 700px;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-backface-visibility: hidden;
  overflow: hidden;
  padding: 40px 70px; }
  .main-paralax--big {
    height: 750px; }
  @supports (-webkit-overflow-scrolling: touch) {
    .main-paralax {
      background-attachment: scroll; } }
  .main-paralax:before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.25); }
  .main-paralax__link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1; }

.menu-language {
  display: flex;
  align-items: center;
  justify-content: center; }
  .menu-language__item {
    font-size: 1.875rem;
    font-weight: 700;
    text-transform: uppercase;
    color: #fff;
    margin: 0 10px;
    padding: 20px;
    border: 1px solid transparent;
    line-height: 1; }
    .menu-language__item.active {
      border-color: #fff;
      color: #50585c; }

.main-nav {
  position: relative;
  display: flex;
  flex-flow: column;
  padding-inline-end: 50px;
  padding-inline-start: 120px;
  width: 55%;
  z-index: 22; }
  .main-nav--secondary {
    width: 45%; }
    .main-nav--secondary .main-nav__label {
      font-size: 2.25rem;
      color: #fff; }
  .main-nav__item {
    margin-top: 3.5rem;
    opacity: 0;
    transition: 0.3s opacity 200ms; }
    .main-nav__item input {
      display: none; }
      .main-nav__item input:checked + span {
        color: #274451; }
        .main-nav__item input:checked + span i:before {
          background-color: #244356; }
        .main-nav__item input:checked + span i:after {
          opacity: 0; }
      .main-nav__item input:checked ~ div {
        max-height: 600px;
        transition: max-height 1.2s ease-in-out; }
  .main-nav__label {
    color: #908c85;
    text-transform: uppercase;
    text-align: left;
    font-size: 2.875rem;
    display: inline-flex;
    align-items: center;
    transition: none;
    line-height: 1; }
  .main-nav__expand {
    position: relative;
    display: inline-block;
    min-width: 25px;
    height: 25px;
    cursor: pointer;
    margin-inline-start: 15px; }
    .main-nav__expand:before, .main-nav__expand:after {
      position: absolute;
      content: '';
      background-color: #908c85;
      transition: opacity .3s ease; }
    .main-nav__expand:before {
      width: 100%;
      height: 3px;
      left: 0;
      top: 50%;
      transform: translateY(-50%); }
    .main-nav__expand:after {
      width: 3px;
      height: 100%;
      left: 50%;
      transform: translateX(-50%); }
  .main-nav__hidden-menu {
    max-height: 0;
    overflow: hidden;
    display: flex;
    align-items: center;
    flex-flow: row wrap;
    transition: max-height 0.8s cubic-bezier(0, 1, 0, 1);
    padding-left: 60px; }
    .main-nav__hidden-menu__item {
      font-size: 1.625rem;
      color: #918674;
      margin: 20px 45px 0 0; }

.services {
  display: flex;
  flex-wrap: wrap; }
  .services__item {
    width: 50%; }
    .services__item:last-child .services__details, .services__item:nth-last-child(2) .services__details {
      padding-bottom: 40px; }
  .services__image {
    position: relative;
    overflow: hidden;
    position: relative; }
    .services__image:before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.1);
      transition: all .3s ease-in-out;
      z-index: 1; }
    .services__image a {
      display: block; }
    .services__image img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: block;
      transition: all .4s ease-in-out; }
  .services__image-link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2; }
  .services__caption {
    position: absolute;
    width: 100%;
    left: 0;
    font-size: 1.875rem;
    font-weight: 700;
    text-align: center;
    bottom: 110px;
    color: #fff;
    z-index: 1; }
    .services__caption:first-letter {
      text-transform: uppercase; }
  .services__info {
    padding: 60px 120px 130px 80px; }
  .services__title {
    font-size: 2.25rem; }
  .services__price {
    margin-inline-end: 15px; }
  .services__link {
    margin-inline-end: 90px; }
  .services__details {
    padding: 25px 80px 100px 80px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }
    .services__details-item {
      padding: 5px;
      font-size: 1.75rem;
      font-weight: 300;
      color: #274451; }
      .services__details-item span {
        font-size: 1rem;
        color: #5a5753;
        font-weight: 400; }

.blog {
  position: relative;
  margin-top: 110px;
  overflow: hidden; }
  .blog--no-carousel {
    margin-top: 80px; }
    .blog--no-carousel .blog__holder {
      display: flex;
      margin-top: 0;
      flex-wrap: wrap; }
    .blog--no-carousel .blog__item {
      flex-basis: 33.33%;
      margin-bottom: 90px; }
  .blog__title {
    font-size: 4.375rem;
    font-weight: 300;
    text-align: center;
    color: #ada9a3; }
  .blog__holder {
    margin-top: 40px;
    margin-left: -2px;
    margin-right: -2px; }
  .blog__item {
    padding: 0 2px;
    margin-bottom: 50px; }
  .blog__image a {
    display: block;
    overflow: hidden; }
  .blog__image img {
    display: block;
    width: 100%;
    transition: all .4s ease-in-out; }
  .blog__info {
    padding: 30px 100px 30px 80px; }
  .blog__category {
    font-size: 1.25rem;
    text-transform: uppercase;
    color: #ada9a2; }
  .blog__item-title {
    font-size: 1.875rem;
    font-weight: 300;
    line-height: 1.2;
    text-transform: uppercase;
    color: #274451;
    margin-top: 30px; }
  .blog__description {
    margin-top: 20px;
    line-height: 1.5;
    color: #5a5753; }
  .blog__bottom {
    margin-top: 30px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between; }
  .blog__price {
    width: 100%;
    font-size: 1.375rem;
    font-weight: 300;
    margin-bottom: 10px;
    color: #274451; }
  .blog__date {
    font-size: 1.25rem;
    font-weight: 700;
    color: #274451; }
  .blog__link {
    font-size: 1.125rem;
    font-weight: 700;
    color: #ada9a2;
    margin-inline-end: 25px; }

.slick-dots {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap; }
  .slick-dots > li {
    margin: 10px 20px;
    border: 2px solid #c2bfba;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    font-size: 0;
    cursor: pointer; }
    .slick-dots > li.slick-active {
      background: #c2bfba; }
    .slick-dots > li > button {
      display: none; }

.footer {
  position: relative;
  display: flex;
  flex-direction: column;
  font-weight: 400;
  padding: 80px 80px 0; }
  .footer__map {
    display: flex;
    flex-direction: column;
    padding: 0 48px 40px 48px;
    background-color: #c2bfba; }
  .footer__logos {
    margin-top: 50px; }
  .footer__logo {
    max-width: 212px;
    margin: -25px auto 0; }
    .footer__logo svg {
      fill: #fff; }
  .footer__gotop {
    position: absolute;
    bottom: 30px;
    width: 16px;
    height: 32px;
    right: 35px;
    cursor: pointer; }
    .footer__gotop svg {
      fill: #c2bfba; }
  .footer__info {
    margin-top: 10px;
    font-weight: 400;
    font-size: 1.375rem;
    line-height: 1.875;
    text-align: center;
    color: #50585c; }
  .footer__navigation {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 1000px;
    margin: 50px auto 0 auto; }
    .footer__navigation-item {
      text-transform: uppercase;
      font-weight: 400;
      font-size: 1.375rem;
      color: #50585c;
      margin: 20px 35px; }
      .footer__navigation-item.active {
        color: #fff; }
  .footer__social {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 50px; }
    .footer__social-item {
      width: 30px;
      height: 30px;
      margin: 0 30px; }
      .footer__social-item svg {
        fill: #fff; }
  .footer__allrights {
    padding: 36px 48px 28px 48px;
    font-size: 1.125rem;
    font-weight: 300;
    text-align: center;
    color: #908d88; }
    .footer__allrights a {
      color: #45433f; }
  .footer__shape {
    width: 130px;
    height: 50px;
    bottom: 0;
    right: 120px; }

.info-section {
  position: relative;
  background-color: #c2bfba;
  padding: 125px 0;
  text-align: center; }
  .info-section--top-space {
    margin-top: 80px; }
  .info-section__title {
    font-size: 5.625rem;
    font-weight: 300;
    color: #fff;
    text-transform: uppercase; }
  .info-section__shape {
    width: 55px;
    height: 55px;
    margin: 30px auto 0 auto;
    fill: #fff; }
  .info-section__text {
    max-width: 100%;
    margin-bottom: 25px; }

.products {
  padding: 0 40px 80px 40px;
  display: flex;
  flex-wrap: wrap; }
  .products__item {
    width: 50%;
    margin-top: 100px;
    padding: 0 40px; }
  .products__image {
    overflow: hidden;
    position: relative; }
    .products__image:before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.1);
      transition: all .3s ease-in-out;
      z-index: 1; }
    .products__image a {
      display: block; }
    .products__image img {
      display: block;
      width: 100%;
      transition: all .4s ease-in-out; }
  .products__image-link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2; }
  .products__title {
    font-size: 2.25rem;
    font-weight: 300;
    text-transform: uppercase;
    margin-top: 40px;
    color: #274451; }
  .products__text {
    font-size: 1.125rem;
    line-height: 1.55;
    margin-top: 20px;
    color: #5a5753;
    padding-right: 80px; }

.filters {
  margin: 0 80px 80px;
  background-color: #c2bfba;
  padding: 100px 50px; }
  .filters__title {
    text-align: center;
    text-transform: uppercase;
    color: #fff;
    font-size: 4.375rem;
    font-weight: 300;
    margin-bottom: 20px; }
  .filters__holder {
    display: flex;
    flex-wrap: wrap; }
  .filters__item {
    flex-basis: 25%;
    padding: 0 20px;
    min-width: 200px;
    margin-top: 50px; }
    .filters__item-label {
      color: #fff;
      cursor: pointer;
      display: block;
      font-size: 1.125rem;
      text-transform: uppercase;
      margin-bottom: 10px; }
    .filters__item-select {
      width: 100%;
      background: transparent;
      border: 2px solid #8d8a85;
      color: #fff;
      cursor: pointer;
      padding: 20px 15px;
      font-size: 1.375rem;
      text-transform: uppercase; }
      .filters__item-select option {
        color: #8d8a85; }
  .filters__buttons {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 70px; }
  .filters__buttons-item {
    border: 2px solid #fff;
    background: #c2bfba;
    color: #fff;
    cursor: pointer;
    font-size: 1.5rem;
    min-width: 250px;
    margin: 15px 35px;
    padding: 15px 20px;
    line-height: 1;
    text-transform: uppercase; }

.nav-years {
  margin-top: 80px; }
  .nav-years__wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center; }
  .nav-years__item {
    margin: 10px 25px;
    font-size: 2.25rem;
    font-weight: 300;
    color: #ada9a2; }
    .nav-years__item.active {
      color: #274451; }

.gallery {
  padding: 40px;
  display: flex;
  flex-wrap: wrap; }
  .gallery__image {
    padding: 40px;
    flex-basis: 50%; }
    .gallery__image a {
      display: block;
      overflow: hidden;
      position: relative; }
      .gallery__image a:before {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.1);
        transition: all .3s ease-in-out;
        z-index: 1; }
    .gallery__image img {
      width: 100%;
      display: block;
      transition: all .4s ease-in-out; }
  .gallery__caption {
    position: absolute;
    bottom: 85px;
    left: 40px;
    right: 40px;
    width: calc(100% - 80px);
    padding: 0 10px;
    text-align: center;
    font-size: 1.5rem;
    font-weight: 700;
    color: #fff;
    z-index: 1; }
    .gallery__caption::first-letter {
      text-transform: uppercase; }

.google-map {
  margin-top: 80px; }

.contacts {
  padding: 100px 0; }
  .contacts__wrapper {
    padding: 0 60px; }
  .contacts__title {
    font-size: 3.75rem;
    font-weight: 300;
    color: #ada9a3;
    text-align: center; }
  .contacts__form {
    margin-top: 100px; }

.form__row {
  margin-top: 45px;
  font-family: "Mada", sans-serif;
  font-size: 0.9375rem; }
  .form__row--two-columns {
    display: flex;
    justify-content: space-between; }
  .form__row--center {
    align-items: center; }
  .form__row--no-margin {
    margin: 0; }
  .form__row--buttons {
    padding: 0 15px;
    text-align: center; }
    .form__row--buttons .btn {
      margin-top: 100px; }

.form__col {
  padding: 0 15px;
  display: flex;
  align-items: flex-end; }
  .form__col-50 {
    width: 50%; }
  .form__col--textarea {
    display: block; }
    .form__col--textarea .form__label {
      margin-bottom: 15px; }
  .form__col--no-padding {
    padding: 0; }

.form__label {
  color: #898580;
  white-space: nowrap;
  text-transform: uppercase;
  margin-right: 10px;
  display: inline-block; }

.form__input, .form__textarea {
  display: block;
  width: 100%;
  border-radius: 0;
  color: #736e67; }
  .form__input::placeholder, .form__textarea::placeholder {
    color: #736e67; }

.form__input {
  padding: 10px 15px;
  font-size: 1.625rem;
  border: none;
  border-bottom: 2px solid #d0cbc5; }

.form__textarea {
  padding: 30px;
  min-height: 240px;
  font-size: 1.125rem;
  resize: vertical;
  border: 2px solid #d0cbc5; }

.form__checkbox {
  display: none; }
  .form__checkbox + .form__label {
    position: relative;
    padding-left: 45px;
    cursor: pointer;
    display: flex;
    align-items: center;
    min-height: 30px;
    margin-left: 55px; }
    .form__checkbox + .form__label:before {
      position: absolute;
      content: '';
      left: 0;
      width: 25px;
      height: 25px;
      border: 2px solid #d0d4d8; }
    .form__checkbox + .form__label:after {
      position: absolute;
      content: '\2714';
      left: 8px;
      top: 7px;
      display: none; }
  .form__checkbox:checked + .form__label:after {
    display: block; }

.btn {
  padding: 20px 90px;
  font-family: "Mada", sans-serif;
  font-size: 32px;
  text-transform: uppercase;
  display: inline-block;
  border: 2px solid #ada9a3;
  background: none;
  cursor: pointer;
  outline: none;
  color: #ada9a3;
  min-width: 170px;
  border-radius: 0;
  -webkit-appearance: none;
  margin: 0 auto;
  line-height: 1; }

.pdp-characteristics {
  padding: 0 80px; }
  .pdp-characteristics__holder {
    background-color: #c2bfba;
    padding: 45px 0;
    text-align: center; }
  .pdp-characteristics__btn {
    font-size: 1.125rem;
    text-transform: uppercase;
    border: 2px solid #fff;
    color: #fff;
    padding: 15px 80px; }
  .pdp-characteristics__title {
    font-size: 3.75rem;
    font-weight: 300;
    color: #274451;
    text-transform: uppercase;
    margin-top: 70px; }
  .pdp-characteristics__price {
    font-size: 2.25rem;
    color: #fff;
    margin-top: 30px; }
  .pdp-characteristics__table {
    width: 100%;
    border-collapse: collapse;
    text-align: left;
    margin-top: 60px; }
    .pdp-characteristics__table tr {
      position: relative;
      border-top: 2px solid #b3afa9; }
    .pdp-characteristics__table td {
      padding: 15px 15px 35px 15px; }
  .pdp-characteristics__key {
    text-transform: uppercase;
    color: #274451; }
  .pdp-characteristics__value {
    font-size: 1.875rem;
    text-transform: uppercase;
    color: #fff; }

.pdp-description {
  margin-top: 70px; }
  .pdp-description p {
    margin-bottom: 25px;
    margin-top: 25px;
    font-size: 1.125rem;
    line-height: 1.66; }

.pdp-gallery {
  margin-top: 100px; }
  .pdp-gallery__holder {
    margin-top: 55px; }

.pdp-text {
  margin-top: 100px; }
  .pdp-text h1, .pdp-text h2, .pdp-text h3, .pdp-text h4, .pdp-text h5, .pdp-text h6 {
    color: #274451;
    font-size: 2.375rem;
    margin-bottom: 10px; }
  .pdp-text p {
    font-size: 1.125rem;
    line-height: 1.66;
    margin-bottom: 55px; }

.pdp-carousel {
  margin-top: 140px; }
  .pdp-carousel .slick-dots {
    margin-top: 30px; }
  .pdp-carousel .slick-arrow {
    display: none !important; }

.pdp-title {
  font-size: 4.375rem;
  font-weight: 300;
  color: #ada9a3;
  display: flex;
  align-items: center;
  justify-content: center; }
  .pdp-title__icon {
    fill: #ada9a3;
    width: 40px;
    height: 40px;
    margin: 0 40px; }

.files-block {
  margin: 20px 0;
  padding-inline-start: 60px; }
  .files-block ul {
    list-style-type: circle;
    padding-inline-start: 25px; }
  .files-block ol {
    list-style-type: upper-roman; }
  .files-block li {
    margin-top: 20px; }

.inner-info {
  margin-top: 45px; }
  .inner-info__btn {
    width: 100%;
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    padding: 15px;
    border: 2px solid #c2bfba;
    color: #736f69;
    text-transform: uppercase;
    text-align: center;
    font-size: 1.125rem; }
  .inner-info__title {
    font-size: 3.75rem;
    font-weight: 300;
    color: #274451;
    text-transform: uppercase;
    text-align: center;
    margin-top: 55px;
    margin-bottom: 0; }
  .inner-info__price {
    text-align: center;
    font-size: 2.25rem;
    color: #ada9a3;
    margin-top: 10px; }
  .inner-info__date {
    text-align: center;
    font-size: 30px;
    font-weight: 700;
    color: #274451;
    margin-top: 90px; }
  .inner-info .pdp-description {
    margin-top: 55px; }

@supports not (margin-inline-start: auto) {
  .header__top-nav {
    margin-left: auto; }
    .header__top-nav--language {
      margin-left: 35px;
      margin-right: auto; }
  .header__menu-close {
    margin-left: auto; }
  .promotions__link {
    margin-right: 25px; }
  .promotions__seemore-icon {
    margin-right: 10px; }
  .main-nav__expand {
    margin-left: 15px; }
  .services__price {
    margin-right: 15px; }
  .services__link {
    margin-right: 90px; }
  .blog__link {
    margin-right: 25px; }
  .hamburger-icon {
    margin-left: 25px; } }

[dir="rtl"] .tp-mask-wrap {
  left: 50%; }

[dir="rtl"] .header__menu {
  background: linear-gradient(to left, white 0%, white 55%, #c2bfba 55%, #c2bfba 100%); }

[dir="rtl"] .footer__gotop {
  right: auto;
  left: 35px; }
  @media (max-width: 767px) {
    [dir="rtl"] .footer__gotop {
      left: 10px; } }

[dir="rtl"] .breadcrumbs__item:after {
  right: auto;
  left: -4px; }

@supports not (margin-inline-start: auto) {
  [dir="rtl"] .header__top-nav {
    margin-right: auto; }
    [dir="rtl"] .header__top-nav--language {
      margin-right: 35px;
      margin-left: auto; }
  [dir="rtl"] .header__menu-close {
    margin-right: auto; }
  [dir="rtl"] .promotions__link {
    margin-left: 25px; }
  [dir="rtl"] .promotions__seemore-icon {
    margin-left: 10px; }
  [dir="rtl"] .main-nav__expand {
    margin-right: 15px; }
  [dir="rtl"] .services__price {
    margin-left: 15px; }
  [dir="rtl"] .services__link {
    margin-left: 90px; }
  [dir="rtl"] .blog__link {
    margin-left: 25px; }
  [dir="rtl"] .hamburger-icon {
    margin-right: 25px; } }

@media (min-width: 1400px) {
  .services__details {
    justify-content: center; }
    .services__details-item {
      margin: 0 20px; } }

@media (hover: hover) {
  .rev_slider_wrapper .tp-bullet:hover {
    background: #fff; }
  .header.active .header__button--menu:hover i {
    background: #00c7d2; }
  .header__button--menu:hover i:nth-of-type(3) {
    width: 100%; }
  .info-text a:hover {
    color: #103640; }
  .promotions__image:hover:before {
    background-color: rgba(0, 0, 0, 0.25); }
  .promotions__image:hover img {
    transform: scale(1.1); }
  .promotions__title:hover {
    color: #7a756c; }
  .promotions__link:hover {
    color: #7a756c; }
  .services__image:hover:before {
    background-color: rgba(0, 0, 0, 0.25); }
  .services__image:hover img {
    transform: scale(1.1); }
  .gallery__image a:hover:before {
    background-color: rgba(0, 0, 0, 0.25); }
  .gallery__image a:hover img {
    transform: scale(1.1); }
  .blog__image img:hover {
    transform: scale(1.1); }
  .blog__category a:hover {
    color: #7a756c; }
  .blog__item-title:hover {
    color: #7a756c; }
  .blog__link:hover {
    color: #7a756c; }
  .footer__navigation-item:hover {
    color: #fff; }
  .footer__social-item:hover svg {
    fill: #50585c; }
  .footer__gotop:hover svg {
    fill: #50585c; }
  .footer__allrights a:hover {
    color: #50585c;
    text-decoration: underline; }
  .menu-language__item:hover {
    border-color: #fff;
    color: #50585c; }
  .main-nav__hidden-menu__item:hover {
    color: #274451; }
  .main-nav__label:hover {
    color: #274451; }
    .main-nav__label:hover i:before,
    .main-nav__label:hover i:after {
      background-color: #244356; }
  .products__image:hover img {
    transform: scale(1.1); }
  .products__title:hover {
    color: #7a756c; }
  .nav-years__item:hover {
    color: #274451; }
  .filters__buttons-item:hover {
    background-color: #716e69;
    border-color: #716e69; }
  .slick-dots > li:hover {
    background: #c2bfba; }
  .breadcrumbs__item:hover {
    color: #2c2923; }
  .btn:hover {
    background-color: #ada9a3;
    color: #fff; }
  .banner__blur-btn:hover {
    background-color: #fff;
    color: #274451; }
  .pdp-characteristics__btn:hover {
    background-color: #716e69;
    border-color: #716e69; }
  .inner-info__btn:hover {
    color: #fff;
    background-color: #716e69;
    border-color: #716e69; } }

@media (max-width: 1300px) {
  .promotions__image {
    width: 55%; }
  .promotions__info {
    width: 45%;
    padding: 0 60px; } }

@media (max-width: 1200px) {
  .blog__info {
    padding: 30px; }
  .header__menu-logo {
    min-width: 100px;
    margin-right: 35px; }
  .header__menu-social-item {
    margin: 10px; }
  .promotions__caption {
    font-size: 3.8rem;
    bottom: 50px; }
  .promotions__title {
    font-size: 2.5rem; }
  .info-section {
    padding: 100px 0; }
    .info-section__title {
      font-size: 4.5rem; }
  .products__item {
    padding: 0 30px; }
  .filters__item {
    flex-basis: 33.33%; }
  .gallery__caption {
    font-size: 1.2rem; }
  .pdp-characteristics__title {
    font-size: 3rem; }
  .pdp-characteristics__price {
    font-size: 2rem; }
  .pdp-characteristics__value {
    font-size: 1.5rem; }
  .pdp-title {
    font-size: 3.8rem; }
  .banner__blur-btn {
    font-size: 3rem; } }

@media (max-width: 1024px) {
  html {
    font-size: 15px; }
  body.noScroll .header__inner {
    padding-right: 65px; }
  body.noScroll .header.fixed .header__inner {
    padding-right: 20px; }
  .header__menu-top {
    padding-top: 10px;
    padding-bottom: 10px; }
  .main-nav--secondary .main-nav__label {
    font-size: 1.5rem; }
  .main-nav__label {
    font-size: 1.8rem; }
  .main-nav__expand {
    min-width: 20px;
    height: 20px; }
    .main-nav__expand:before {
      height: 2px; }
    .main-nav__expand:after {
      width: 2px; }
  .main-nav__hidden-menu__item {
    font-size: 1.2rem; }
  .border-rectangle--small,
  .border-rectangle--medium {
    width: calc(100% - 90px);
    height: calc(100% - 90px);
    top: 45px;
    left: 45px; }
  .rev_slider_wrapper .tp-bullets {
    left: calc(100% + 25px) !important; }
  .rev_slider_wrapper .border-rectangle--small {
    top: 65px;
    height: calc(100% - 130px); }
  .promotions__info {
    padding-left: 3rem;
    padding-right: 3rem; }
  .services__info {
    padding-right: 80px; }
  .services__caption {
    font-size: 1.85rem;
    bottom: 60px; }
  .info-section {
    padding: 80px 0; }
    .info-section__title {
      font-size: 4rem; }
    .info-section__text {
      margin: 25px auto 10px auto; }
  .products {
    padding: 0 20px 40px 20px; }
    .products__item {
      margin-top: 100px; }
      .products__item:last-child {
        margin-bottom: 60px; }
    .products__title {
      font-size: 1.8rem; }
    .products__text {
      padding-right: 0; }
  .filters__title {
    font-size: 3.2rem; }
  .filters__item-select {
    padding: 15px;
    font-size: 1.1rem; }
  .filters__buttons-item {
    padding: 15px;
    font-size: 1.3rem; }
  .slick-dots > li {
    margin: 10px; }
  .gallery__caption {
    bottom: 60px; }
  .blog {
    margin-top: 60px; }
  .form__input, .form__textarea {
    -webkit-appearance: none; } }

@media (max-width: 992px) {
  .wrapper {
    padding-left: 20px;
    padding-right: 20px; }
  body.noScroll .header__inner {
    padding-right: 20px; }
  .header.fixed .header__inner {
    padding: 10px 20px; }
  .header__menu-social {
    display: none; }
  .header__inner {
    padding: 10px 20px; }
  .header__logo {
    height: 33px;
    width: 130px; }
  .header__menu {
    background: #fff; }
    .header__menu-top {
      padding: 10px 25px; }
      .header__menu-top-left {
        width: 100px; }
      .header__menu-top-right {
        width: calc(100% - 100px); }
    .header__menu-logo {
      width: 75px;
      height: 35px;
      min-width: 30px;
      flex-basis: 75px;
      margin-right: 15px; }
      .header__menu-logo-icon {
        width: 70px; }
    .header__menu-language {
      padding-inline-start: 0; }
    .header__menu-close {
      width: 20px;
      height: 20px; }
  .header__menu-nav {
    flex-direction: column; }
  .rev_slider_wrapper__scroll-down {
    bottom: 120px; }
  .menu-language__item {
    font-size: 16px;
    margin: 5px;
    padding: 0;
    margin: 10px 15px;
    color: #908c85; }
  .main-nav {
    width: 100%;
    padding-block-start: 25px;
    padding-inline-end: 25px;
    padding-inline-start: 25px;
    padding-bottom: 50px; }
    .main-nav--secondary {
      background-color: #c2bfba; }
      .main-nav--secondary .main-nav__label {
        font-size: 1.4rem; }
    .main-nav__item {
      margin-top: 30px; }
    .main-nav__label {
      font-size: 1.4rem; }
    .main-nav__expand {
      min-width: 20px;
      height: 20px; }
    .main-nav__hidden-menu {
      padding-left: 30px; }
      .main-nav__hidden-menu__item {
        font-size: 14px;
        margin: 20px 20px 0 0; }
  .rev_slider_wrapper--innerpage .slotholder {
    height: 100vh;
    min-height: 500px; }
  .rev_slider_wrapper .tp-bullets {
    top: auto !important;
    left: 50% !important;
    bottom: 30px;
    transform: rotate(-90deg) translateX(-50%) !important;
    margin-left: -3px; }
  .rev_slider_wrapper .tp-bullet {
    transform: rotate(90deg); }
  .rev_slider_wrapper .main_caption_name {
    font-size: 28px !important;
    line-height: 1.3 !important; }
    .rev_slider_wrapper .main_caption_name--subtitle {
      font-size: 20px !important; }
  .welcome {
    padding: 0;
    margin: 0; }
    .welcome__container {
      padding: 40px 0px; }
    .welcome__logo {
      width: 140px;
      height: 140px;
      margin-left: auto;
      margin-right: auto; }
    .welcome__text {
      margin: 0;
      max-width: 100%; }
  .advertise-logos {
    margin: 30px 0; }
  .promotions {
    padding: 0; }
    .promotions__item {
      flex-direction: column;
      margin-top: 60px; }
      .promotions__item:last-child {
        margin-bottom: 60px; }
      .promotions__item--type2 .promotions__info {
        order: 3; }
    .promotions__image {
      width: 100%;
      margin: 0;
      z-index: 2; }
    .promotions__caption {
      font-size: 3rem; }
    .promotions__info {
      width: 100%;
      padding: 40px 20px;
      margin: 0; }
    .promotions__bottom {
      margin-top: 30px; }
    .promotions__title {
      display: inline-block;
      font-size: 2rem; }
    .promotions__description {
      font-size: 1rem; }
    .promotions__price {
      font-size: 1.25rem; }
  .banner {
    margin: 0; }
    .banner__container {
      padding: 45px 0; }
    .banner__pattern {
      width: 200px;
      height: 200px;
      left: 0;
      right: 0;
      margin: auto; }
    .banner__shape {
      width: 60px;
      height: 60px; }
    .banner__title {
      font-size: 2rem; }
      .banner__title span {
        font-size: 6rem; }
    .banner__subtitle {
      font-size: 2rem;
      padding: 30px 0 45px; }
    .banner__description {
      padding: 30px 0 45px !important;
      font-size: 1rem; }
  .main-paralax {
    height: 550px;
    padding: 20px; }
    .main-paralax--big {
      height: 600px; }
  .services {
    flex-direction: column;
    align-items: center;
    justify-content: center; }
    .services__item {
      width: 100%; }
    .services__info {
      padding: 40px 20px; }
    .services__details {
      padding: 20px 20px 80px 20px; }
    .services__caption {
      bottom: 70px; }
  .blog {
    padding: 40px 20px;
    margin: 0;
    overflow: hidden; }
    .blog--no-carousel .blog__item {
      flex-basis: 50%; }
    .blog__info {
      padding: 20px 0; }
    .blog__shape {
      top: 15px;
      right: -80px;
      width: 160px;
      height: 160px; }
    .blog__title {
      font-size: 38px;
      padding-right: 0; }
    .blog__category {
      padding-right: 0; }
    .blog__item-title {
      font-size: 2rem; }
    .blog__link {
      margin-inline-end: 35px; }
  .footer {
    padding: 0;
    margin-top: 40px; }
    .footer__logo {
      max-width: 180px;
      height: 140px; }
    .footer__map {
      padding: 0 20px 20px 20px; }
    .footer__info {
      font-size: 1rem; }
    .footer__logos {
      margin-bottom: 0; }
    .footer__navigation {
      margin-top: 20px; }
      .footer__navigation-item {
        font-size: 1rem;
        margin: 10px 15px; }
    .footer__social-item {
      width: 30px;
      height: 30px; }
    .footer__allrights {
      padding: 20px;
      font-size: 1rem; }
    .footer__gotop {
      width: 13px;
      height: 26px;
      bottom: 20px; }
  .info-section {
    padding: 60px 0; }
    .info-section__title {
      font-size: 3rem; }
  .products__title {
    margin-top: 40px;
    font-size: 1.6rem; }
  .products__text {
    margin-top: 15px; }
  .filters {
    margin: 0 40px 40px;
    background-color: #c2bfba;
    padding: 60px 30px; }
  .nav-years {
    margin-top: 40px; }
    .nav-years__item {
      font-size: 1.8rem; }
  .gallery__image {
    padding: 20px; }
  .btn {
    width: 100%;
    text-align: center;
    padding: 20px; }
  .contacts {
    padding: 50px 0; }
    .contacts__title {
      font-size: 2.5rem; }
    .contacts__form {
      margin-top: 50px; }
  .form__row .btn {
    margin-top: 50px; }
  .form__input {
    font-size: 1.3rem; }
  .breadcrumbs {
    padding: 20px 10px; }
  .pdp-characteristics {
    padding: 0; }
  .pdp-carousel .slick-dots {
    margin-bottom: 80px; }
  .pdp-title {
    font-size: 3rem; } }

@media (max-width: 767px) {
  .rev_slider_wrapper .tp-caption {
    min-width: 300px !important; }
  .header__logo {
    min-width: 30px; }
  .header__top-nav--main {
    display: none; }
  .header__top-nav--language {
    margin-inline-start: 15px; }
  .info-text {
    padding: 0; }
  .border-rectangle,
  .border-rectangle--small,
  .border-rectangle--medium {
    left: 28px;
    top: 28px;
    width: calc(100% - 50px);
    height: calc(100% - 50px); }
  .breadcrumbs__item {
    margin: 5px 0; }
  .banner__inner-shape {
    width: 30px;
    height: 30px; }
  .banner__title {
    padding: 0 20px; }
    .banner__title span {
      font-size: 4rem; }
  .banner__blur-icon {
    width: 60px;
    height: 60px; }
  .banner__blur-btn {
    padding: 15px 25px;
    font-size: 1.8rem; }
  .welcome--inner-page .welcome__text {
    margin-top: 25px; }
  .blog--no-carousel .blog__item {
    flex-basis: 100%;
    margin-bottom: 70px; }
    .blog--no-carousel .blog__item:last-child {
      margin-bottom: 0; }
  .services__info {
    margin-bottom: 60px; }
  .services__caption {
    bottom: 50px;
    font-size: 1.5rem; }
    .services__caption::first-letter {
      text-transform: uppercase; }
  .blog__holder {
    margin-left: 0;
    margin-right: 0; }
  .blog__item {
    padding: 0;
    margin-bottom: 30px; }
  .advertise-logos {
    flex-flow: column;
    justify-content: center; }
    .advertise-logos__item {
      margin: 30px 15px; }
  .promotions__caption {
    font-size: 2rem;
    bottom: 35px; }
  .promotions__title {
    line-height: 1.2; }
  .promotions__description {
    margin-top: 15px; }
  .promotions__link {
    font-size: 1rem;
    margin-inline-end: 30px; }
  .footer__logo {
    max-width: 150px;
    height: 110px;
    margin-top: -20px; }
  .footer__info {
    font-size: 14px;
    padding: 0 20px; }
  .footer__social {
    justify-content: center;
    margin-top: 40px; }
    .footer__social-item {
      width: 25px;
      height: 25px;
      margin: 10px 15px; }
  .footer__allrights {
    position: relative;
    padding-inline-start: 35px;
    padding-inline-end: 35px; }
  .footer__gotop {
    bottom: auto;
    top: 50%;
    transform: translateY(-50%);
    right: 10px; }
  .info-section__shape {
    width: 40px;
    height: 40px; }
  .products__item {
    width: 100%;
    padding: 0; }
  .filters {
    margin: 0 20px 40px 20px;
    background-color: #c2bfba;
    padding: 40px 10px; }
    .filters__title {
      font-size: 2.5rem; }
    .filters__item {
      flex-basis: 50%;
      min-width: 150px;
      padding: 0 10px;
      margin-top: 35px; }
      .filters__item-select {
        font-size: 1rem; }
      .filters__item-label {
        font-size: 1rem; }
    .filters__buttons {
      margin-top: 40px; }
      .filters__buttons-item {
        padding: 10px;
        font-size: 1.2rem;
        margin: 15px; }
  .nav-years__item {
    font-size: 1.4rem;
    margin: 10px 15px; }
  .gallery {
    padding-left: 0;
    padding-right: 0; }
    .gallery__image {
      flex-basis: 100%; }
    .gallery__caption {
      bottom: 35px; }
  .form__row {
    margin-top: 25px; }
    .form__row--two-columns {
      display: block; }
    .form__row--buttons {
      padding: 0;
      width: 100%;
      text-align: left; }
      .form__row--buttons .form__col {
        display: block; }
    .form__row .btn, .form__row .gcaptcha {
      margin-top: 20px; }
  .form__col {
    padding: 0; }
    .form__col-50 {
      width: 100%; }
      .form__col-50 + .form__col-50 {
        margin-top: 25px; }
  .form__input {
    font-size: 14px; }
  .form__textarea {
    font-size: 14px;
    padding: 20px; }
  .btn {
    font-size: 26px;
    padding: 10px 20px; }
  .slick-dots {
    margin-bottom: 40px; }
    .slick-dots > li {
      width: 15px;
      height: 15px; }
  .pdp-characteristics__btn {
    width: 100%;
    padding: 15px; }
  .pdp-characteristics__title {
    margin-top: 50px;
    font-size: 2rem; }
  .pdp-characteristics__price {
    font-size: 1.8rem; }
  .pdp-characteristics__table {
    display: block;
    overflow-y: scroll; }
    .pdp-characteristics__table td {
      padding: 10px 10px 20px 10px; }
  .pdp-characteristics__value {
    font-size: 1.2rem; }
  .pdp-description {
    margin-top: 50px; }
  .pdp-gallery {
    margin-top: 50px; }
    .pdp-gallery__holder {
      margin-top: 30px; }
  .pdp-title {
    font-size: 2rem; }
    .pdp-title__icon {
      margin: 0 25px;
      width: 30px;
      height: 30px; }
  .pdp-text {
    margin-top: 50px; }
    .pdp-text h1, .pdp-text h2, .pdp-text h3, .pdp-text h4, .pdp-text h5, .pdp-text h6 {
      font-size: 2rem; }
  .pdp-carousel {
    margin-top: 70px; }
  .inner-info__title {
    font-size: 30px;
    margin-top: 40px; }
  .inner-info__price {
    font-size: 22px; }
  .inner-info__date {
    margin-top: 35px;
    font-size: 22px; }
  .inner-info .pdp-description {
    margin-top: 25px; } }

@media (max-width: 480px) {
  .filters__title {
    font-size: 1.8rem; }
  .filters__item {
    flex-basis: 100%;
    min-width: 150px;
    margin-top: 20px; }
  .filters__item-select {
    padding: 10px 15px; }
  .filters__buttons {
    margin-top: 20px; }
  .filters__buttons-item {
    font-size: 1.1rem; }
  .blog__item {
    margin-bottom: 20px; }
  .form__row--buttons .form__col {
    flex-wrap: wrap; }
  .form__checkbox + .form__label {
    margin-left: 0; } }
