* {
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: transparent;
    -webkit-text-size-adjust: 100%;
    transition: all .2s ease;
    box-sizing: border-box;
}

html, body {
    &.noScroll {
        position: relative;
        overflow: hidden;
    }
}

html {
    height: 100%;
    font-size: $rem-baseline;
}

body {
    color: $main-text-color;
    line-height: 1.3;
    font-family: $font-base;

    &.noScroll {
  
        .header__inner {
            padding-right: 79px;
        }

        .header.fixed .header__inner {
            padding-right: 40px;
        }
    } 
}

a {
    display: inline-block;
    color: inherit;
    text-decoration: none;
}

img {
    max-width: 100%;
}

svg {
    max-width: 100%;
    max-height: 100%;
}

.wrapper {
    width: 100%;
    max-width: 1140px;
    margin: 0 auto;
    padding-left: 15px;
    padding-right: 15px;
}

.border-rectangle {
    position: absolute;
    width: calc(100% - 90px);
    height: calc(100% - 90px);
    top: 45px;
    left: 45px;
    pointer-events: none;
    z-index: 10;

    &--medium {
        width: calc(100% - 130px);
        height: calc(100% - 130px);
        top: 65px;
        left: 65px;
    }

    &--small {
        width: calc(100% - 160px);
        height: calc(100% - 160px);
        top: 80px;
        left: 80px;
    }

    &:before, &:after {
        position: absolute;
        content: '';
        width: 0;
        height: 0;
        bottom: 0;
        right: 0;
        perspective: 1000;
        transform: translateZ(0);
        border: 2px solid rgba(11,12,16,0);
        transition-delay: 1s;
    }

    &.active {
        &:before {
            width: 100%;
            height: 100%;
            border-bottom-color: #fff;
            border-left-color: #fff;
            transition: width .7s ease-out .7s,height .7s ease-out 1.4s;
        }

        &:after {
            width: 100%;
            height: 100%;
            border-right-color: #fff;
            border-top-color: #fff;
            transition: height .7s ease-out .7s,width .7s ease-out 1.4s;
        }
    }
}

.hamburger-icon {
    width: 32px;
    height: 16px;
    cursor: pointer;
    display: flex;
    flex-flow: column;
    margin-inline-start: 25px;

    i {
        height: 2px;
        width: 100%;
        background: #fff;
        margin-bottom: 5px;
        display: block;

        &:nth-child(3) {
            width: 65%;
        }
    }
}

.advertise-logos {
    display: flex;
    align-items: center;
    justify-content: center;

    &__item {
        margin: 0 45px;
    }
}

.info-text {
    font-size: 1.25rem;
    line-height: 1.6;
    text-align: center;
    margin: 40px auto 10px auto;
    padding: 0 80px;
    color: #fff;

    a {
        color: #50585c;
    }
}

@keyframes sdb {
    0% {
        opacity: 0;
    }
    30% {
        opacity: 1;
    }
    60% {
        box-shadow: 0 0 0 60px rgba(255, 255, 255, 0.1);
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}

@mixin darken-image($opacity: 0.1) {
    position: relative;

    &:before {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, $opacity);
        transition: all .3s ease-in-out;
        z-index: 1;
    }
}

@mixin link-hover-style() {
    position: relative;

    &:after {
        position: absolute;
        content: '';
        width: 100%;
        height: 2px;
        bottom: -2px;
        left: 0;
        background-color: #fff;
        transition: all .3s ease-in-out;
        opacity: 0;
    }

    @media (hover: hover) {
        &:hover:after {
            opacity: 1;
        }
    }
}

#sliderMain {
    min-height: 1080px;

    * {
        transition: none;
    }
}

.rev_slider_wrapper {
    position: relative;
    z-index: 10;

    .slotholder {
        position: relative;

        &:before {
            position: absolute;
            content: '';
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.25);
        }
    }

    .main_caption_name {
        position: relative;
        font-family: $font-base;
        font-size: 4.375rem;
        font-weight: $font-light;
        text-transform: uppercase;
        line-height: 1.1;
        color: $main-slider-text-color;

        &--subtitle {
            font-size: 2rem;
        }
    }

    &__text-layer {
        color: $main-slider-text-color !important;
        text-transform: uppercase;
    }

    &__scroll-down {
        position: absolute;
        bottom: 150px;
        left: 50%;
        margin: auto;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        cursor: pointer;
        transform: translate(0, -50%);
        transition: opacity 0.5s;
        z-index: 20;

        &:after {
            position: absolute;
            content: '';
            width: 16px;
            height: 16px;
            margin-top: -5px;
            border-left: 1px solid #fff;
            border-bottom: 1px solid #fff;
            transform: rotate(-45deg);
            box-sizing: border-box;
        }

        &:before {
            position: absolute;
            // top: -22px;
            // left: -22px;
            z-index: -1;
            content: '';
            width: 44px;
            height: 44px;
            box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.1);
            border-radius: 100%;
            opacity: 0;
            animation: sdb 3s infinite;
            box-sizing: border-box;
        }
    }

    .tp-caption {
        white-space: inherit !important;
        text-align: center !important;
    }

    .tp-bullets {
        left: calc(100% + 15px) !important;
    }

    .tp-bullet {
        border-radius: 50%;
        width: 12px;
        height: 12px;

        &:after {
            display: none;
        }

        &.selected {
            background: #fff;
        }
    }

    &--innerpage {
        .slotholder {
            height: 100vh;
            background-size: cover;
        }

        .border-rectangle {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .tp-bullets {
            display: none !important;
        }
    }

    &--small .slotholder {
        height: 605px
    }
}

.breadcrumbs {
    position: relative;
    padding: 30px 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    &__item {
        position: relative;
        font-family: $font-base;
        font-size: 1rem;
        color: #736f69;
        padding: 0 8px;
        z-index: 2;

        &:last-child:after {
            display: none;
        }

        &:after {
            position: relative;
            content: ' »';
            right: -4px;
        }
    }
}

.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 20;
    $this: &;
   
    &.fixed {
        background-color: $header-sticky-background-color;

        #{$this}__logo {
            max-width: 70px;
        }

        #{$this}__inner {
            padding-top: 12px;
            padding-bottom: 12px;
        }
        
        #{$this}__top-nav--main {
            display: none;
        }
    }

    &.active {

        #{$this}__inner {
            display: none;
        }

        .header__button {
            background: #f0f3f4;

            i {
                background: #185478;
            }
            
            span {
                opacity: 0;
            }
        }
    }

    &.fixed.active .header__menu-top {
        padding-top: 10px;
    }

    &__inner {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px 30px;
        transition: none;
    }

    &__top-nav {
        display: flex;
        align-items: center;
        margin-inline-start: auto;

        &--language {
            margin-inline-start: 35px;
            margin-inline-end: auto;
        }
    }

    &__top-nav-link {
        color: #fff;
        font-size: 1.125rem;
        text-transform: uppercase;
        margin: 0 15px;
        @include link-hover-style();

        &.active {
            font-weight: $font-bold;
        }
    }

    &__logo {
        max-width: 80px;
        height: 30px;

        &__icon {
            width: 100%;
            fill: #fff;
        }
    }

    &__button {
        position: relative;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        z-index: 21;
    }

    &__menu {
        position: fixed;
        top: 0;
        width: 100%;
        height: 100vh;
        min-height: 100vh;
        background: linear-gradient(to right, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 55%, #c2bfba 55%, #c2bfba 100%);
        visibility: hidden;
        overflow-y:auto;
        opacity: 0;
        z-index: 20;
        
        &.active {
            opacity: 1;
            visibility: visible;

            .main-nav__item {
                opacity: 1;
            }
        }

        &-top {
            display: flex;
            align-items: center;
            padding: 40px 40px;

            &-left {
                width: 55%;
                display: flex;
                align-items: center;
            }

            &-right {
                width: 44%;
                display: flex;
                align-items: center;
            }
        }

        &-logo {
            min-width: 125px;
            flex-basis: 125px;
            height: 65px;
            margin-right: 60px;

            &-icon {
                fill: #a4a09a;
            }
        }

        &-social {

            &-item {
                width: 27px;
                height: 27px;
                margin: 10px 25px;
                
                svg {
                    fill: #a4a09a;
                }
            }
        }

        &-language {
            padding-inline-start: 120px;
        }

        &-close {
            min-width: 20px;
            width: 25px;
            height: 25px;
            fill: #777777;
            cursor: pointer;
            margin-inline-start: auto;
        }

        &-nav {
            display: flex;
            justify-content: center;
        }
    }
}

.welcome {
    margin-top: 80px;
    padding: 0 80px;

    &--inner-page {
        margin-top: 0;

        .info-section__shape {
            margin-bottom: 30px;
        }
    }

    &__container {
        padding: 50px 60px;
        text-align: center;
        background-color: #c2bfba;
    }

    &__logo {
        max-width: 280px;
        margin: auto;

        svg {
            fill: #fff;
        }
    }

    &__text {
        font-size: 1.25rem;
        line-height: 1.6;
        margin: 40px auto 10px auto;
        color: #fff;

        a {
            color: #50585c;
        }
    }

    &__logos {
        margin: 60px 0;
    }
}

.promotions {
    $this: &;

    &__item {
        display: flex;
        align-items: center;
        margin-top: 80px;

        &--type2 {
            #{$this}__image {
                order: 2;
            }
        }
    }

    &__image {
        position: relative;
        width: 60%;
        @include darken-image();
        overflow: hidden;

        img {
            display: block;
            width: 100%;
            transition: all .4s ease-in-out;
        }

        a {
            display: block;
        }
    }

    &__image-link {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
    }

    &__caption {
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 65px;
        font-size: 4.375rem;
        font-weight: $font-light;
        text-align: center;
        color: #fff;
        z-index: 1;
    }

    &__info {
        width: 40%;
        padding: 0 80px;
    }

    &__title {
        font-size: 2.875rem;
        line-height: 1.1;
        font-weight: $font-light;
        text-transform: uppercase;
        animation-delay: 0.3s;
        color: #274451;
    }

    &__description {
        font-size: $paragraph-font-size;
        line-height: $paragraph-line-height;
        margin-top: 30px;
        animation-delay: 0.5s;
    }

    &__bottom {
        margin-top: 50px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        animation-delay: 0.7s;
    }

    &__price {
        color: #274451;
        font-weight: $font-light;
        font-size: 1.75rem;
    }

    &__link {
        display: flex;
        align-items: center;
        font-size: 1.25rem;
        font-weight: $font-bold;
        color: #ada9a2;
        margin-inline-end: 25px;
    }

    &__seemore-icon {
        width: 50px;
        height: 20px;
        margin-inline-end: 10px;
    }
}

.banner {
    position: relative;
    margin-top: 80px;
    background-color: #c2bfba;
    font-weight: $font-light;

    &--no-padding {
        padding: 0;
    }

    &--no-margin {
        margin: 0;
    }

    &--light {
        background-color: #dcdad8;
    }

    &--blur {
        overflow: hidden;

        .banner__image {
            filter: blur(9px);
        }
    } 

    &--no-text .banner__pattern--bottom {
        top: auto;
        bottom: 0;
    }

    &__container {
        position: relative;
        padding: 80px;
    }

    &__pattern {
        position: absolute;
        width: 385px;
        height: 385px;
        fill: #cfccc6;

        &--top {
            top: 0;
            left: 0;
        }

        &--bottom {
            right: 0;
            bottom: 0;
        }
    }

    &__image {
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__text {
        text-align: center;
        z-index: 1;
    }

    &__title {
        font-size: 4.375rem;
        color: $secondary-text-color;
        line-height: 1.15;

        span {
            display: block;
            font-size: 10rem;
            text-transform: uppercase;
        }
    }

    &__subtitle {
        text-align: center;
        font-size: 3.125rem;
        color: #50585c;
        padding: 50px 0 80px;
    }

    &__description {
        margin: 0;
        padding: 50px 100px 80px 100px;
        font-weight: $font-regular;
    }

    &__shape {
        width: 110px;
        height: 115px;
        fill: #fff;
    }

    &__inner-shape {
        position: absolute;
        width: 50px;
        height: 50px;
        fill: #fff;

        &--top {
            top: 10px;
            left: 10px;
        }

        &--right {
            top: 10px;
            right: 14px;
        }

        &--bottom {
            bottom: 14px;
            right: 14px;
        }

        &--left {
            bottom: 14px;
            left: 12px;
        }
    }

    &__blur-info {
        position: absolute;
        width: 100%;
        top: 50%;
        text-align: center;
        transform: translateY(-50%);
        z-index: 2;
    }

    &__blur-icon {
        width: 110px;
        height: 110px;
        fill: #fff;
    }

    &__blur-btn {
        font-size: 4rem;
        font-weight: $font-regular;
        border: 2px solid #fff;
        color: #fff;
        padding: 45px 110px;
        text-transform: uppercase;
        margin-top: 50px;
    }

    &__bottom {
        background-color: #c2bfba;
    }
}

.main-paralax {
    position: relative;
    height: 700px;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    -webkit-backface-visibility: hidden;
    overflow: hidden;
    padding: 40px 70px;

    &--big {
        height: 750px;
    }

    @supports (-webkit-overflow-scrolling: touch) {
        background-attachment: scroll;
    }

    &:before {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: rgba(0,0,0, 0.25);
    }

    &__link {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
    }
}

.menu-language {
    display: flex;
    align-items: center;
    justify-content: center;

    &__item {
        font-size: 1.875rem;
        font-weight: $font-bold;
        text-transform: uppercase;
        color: #fff;
        margin: 0 10px;
        padding: 20px;
        border: 1px solid transparent;
        line-height: 1;;

        &.active {
            border-color: #fff;
            color: #50585c;
        }
    }
}

.main-nav {
    position: relative;
    display: flex;
    flex-flow: column;
    padding-inline-end: 50px;
    padding-inline-start: 120px;
    width: 55%;
    z-index: 22;

    &--secondary {  
        width: 45%;

        .main-nav {

            &__label {
                font-size: 2.25rem;
                color: #fff;
            }
        }
    }

    &__item {
        margin-top: 3.5rem;
        opacity: 0;
        transition: 0.3s opacity 200ms;

        input {
            display: none;

            &:checked {
                & + span {
                    color: $header-menu-item-active-color;

                    i {

                        &:before {
                            background-color: #244356;
                        }

                        &:after {
                            opacity: 0;
                        }
                    }
                }

                & ~ div {
                    max-height: 600px;
                    transition: max-height 1.2s ease-in-out;
                }
            }
        }
    }

    &__label {
        color: $header-menu-item-color;
        text-transform: uppercase;
        text-align: left;
        font-size: 2.875rem;
        display: inline-flex;
        align-items: center;
        transition: none;
        line-height: 1;
    }

    &__expand {
        position: relative;
        display: inline-block;
        min-width: 25px;
        height: 25px;
        cursor: pointer;
        margin-inline-start: 15px;

        &:before, &:after {
            position: absolute;
            content: '';
            background-color: $header-menu-item-color;
            transition: opacity .3s ease;
        }

        &:before {
            width: 100%;
            height: 3px;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
        }

        &:after {
            width: 3px;
            height: 100%;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    &__hidden-menu {
        max-height: 0;
        overflow: hidden;
        display: flex;
        align-items: center;
        flex-flow: row wrap;
        transition: max-height 0.8s cubic-bezier(0, 1, 0, 1);
        padding-left: 60px;

        &__item {
            font-size: 1.625rem;
            color: $header-menu-inner-item-color;
            margin: 20px 45px 0 0;
        }
    }
}

.services {
    display: flex;
    flex-wrap: wrap;

    &__item {
        width: 50%;

        &:last-child,
        &:nth-last-child(2) {
            .services__details {
                padding-bottom: 40px;
            }
        }
    }

    &__image {
        position: relative;
        overflow: hidden;
        @include darken-image();

        a {
            display: block;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            display: block;
            transition: all .4s ease-in-out;
        }
    }

    &__image-link {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
    }

    &__caption {
        position: absolute;
        width: 100%;
        left: 0;
        font-size: 1.875rem;
        font-weight: $font-bold;
        text-align: center;
        bottom: 110px;
        color: #fff;
        z-index: 1;

        &:first-letter {
            text-transform: uppercase;
        }
    }

    &__info {
        padding: 60px 120px 130px 80px;
    }

    &__title {
        font-size: 2.25rem;
    }

    &__price {
        margin-inline-end: 15px;
    }

    &__link {
        margin-inline-end: 90px;
    }

    &__details {
        padding: 25px 80px 100px 80px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;

        &-item {
            padding: 5px;
            font-size: 1.75rem;
            font-weight: $font-light;
            color: #274451;

            span {
                font-size: 1rem;
                color: #5a5753;
                font-weight: $font-regular;
            }
        }
    }
}

.blog {
    position: relative;
    margin-top: 110px;
    overflow: hidden;
    $this: &;

    &--no-carousel {
        margin-top: 80px;

        #{$this}__holder {
            display: flex;
            margin-top: 0;
            flex-wrap: wrap;
        }

        #{$this}__item {
            flex-basis: 33.33%;
            margin-bottom: 90px;
        }
    }

    &__title {
        font-size: 4.375rem;
        font-weight: $font-light;
        text-align: center;
        color: #ada9a3;
    }

    &__holder {
        margin-top: 40px;
        margin-left: -2px;
        margin-right: -2px;
    }

    &__item {
        padding: 0 2px;
        margin-bottom: 50px;
    }

    &__image {

        a {
            display: block;
            overflow: hidden;
        }
        
        img {
            display: block;
            width: 100%;
            transition: all .4s ease-in-out;
        }
    }

    &__info {
        padding: 30px 100px 30px 80px;
    }

    &__category {
        font-size: 1.25rem;
        text-transform: uppercase;
        color: #ada9a2;
    }

    &__item-title {
        font-size: 1.875rem;
        font-weight: $font-light;
        line-height: 1.2;
        text-transform: uppercase;
        color: #274451;
        margin-top: 30px;
    }

    &__description {
        margin-top: 20px;
        line-height: 1.5;
        color: #5a5753;
    }

    &__bottom {
        margin-top: 30px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    &__price {
        width: 100%;
        font-size: 1.375rem;
        font-weight: $font-light;
        margin-bottom: 10px;
        color: #274451;
    }

    &__date {
        font-size: 1.25rem;
        font-weight: $font-bold;
        color: #274451;
    }

    &__link {
        font-size: 1.125rem;
        font-weight: $font-bold;
        color: #ada9a2;
        margin-inline-end: 25px;
    }
}

.slick-dots {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    > li {
        margin: 10px 20px;
        border: 2px solid #c2bfba;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        font-size: 0;
        cursor: pointer;

        &.slick-active {
            background: #c2bfba;
        }
        
        > button {
            display: none;
        }
    }
}

.footer {
    position: relative;
    display: flex;
    flex-direction: column;
    font-weight: $font-regular;
    padding: 80px 80px 0;

    &__map {
        display: flex;
        flex-direction: column;
        padding: 0 48px 40px 48px;
        background-color: #c2bfba;
    }

    &__logos {
        margin-top: 50px;
    }

    &__logo {
        max-width: 212px;
        margin: -25px auto 0;

        svg {
            fill: #fff;
        }
    }

    &__gotop {
        position: absolute;
        bottom: 30px;
        width: 16px;
        height: 32px;
        right: 35px;
        cursor: pointer;

        svg {
            fill: #c2bfba;
        }
    }
    
    &__info {
        margin-top: 10px;
        font-weight: $font-regular;
        font-size: 1.375rem;
        line-height: 1.875;
        text-align: center;
        color: #50585c;
    }

    &__navigation {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        max-width: 1000px;
        margin: 50px auto 0 auto;

        &-item {
            text-transform: uppercase;
            font-weight: $font-regular;
            font-size: 1.375rem;
            color: $footer-text-color;
            margin: 20px 35px;

            &.active {
			    color: $footer-text-hover-color;
            }
        }
    }

    &__social {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 50px;

        &-item {
            width: 30px;
            height: 30px;
            margin: 0 30px;

            svg {
                fill: $footer-social-color;
            }
        }
    }
   
    &__allrights {
        padding: 36px 48px 28px 48px;
        font-size: 1.125rem;
        font-weight: $font-light;
        text-align: center;
        color: $footer-bottom-text-color;

        a {
            color: #45433f;
        }
    }

    &__shape {
        width: 130px;
        height: 50px;
        bottom: 0;
        right: 120px;
    }
}

.info-section {
    position: relative;
    background-color: #c2bfba;
    padding: 125px 0;
    text-align: center;

    &--top-space {
        margin-top: 80px;
    }

    &__title {
        font-size: 5.625rem;
        font-weight: $font-light;
        color: #fff;
        text-transform: uppercase;
    }

    &__shape {
        width: 55px;
        height: 55px;
        margin: 30px auto 0 auto;
        fill: #fff;
    }
    
    &__text {
        max-width: 100%;
        margin-bottom: 25px;
    }
}

.products {
    padding: 0 40px 80px 40px;
    display: flex;
    flex-wrap: wrap;

    &__item {
        width: 50%;
        margin-top: 100px;
        padding: 0 40px;
    }

    &__image {
        overflow: hidden;
        @include darken-image();

        a {
            display: block;
        }

        img {
            display: block;
            width: 100%;
            transition: all .4s ease-in-out;
        }
    }

    &__image-link {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
    }

    &__title {
        font-size: 2.25rem;
        font-weight: $font-light;
        text-transform: uppercase;
        margin-top: 40px;
        color: #274451;
    }

    &__text {
        font-size: $paragraph-font-size;
        line-height: $paragraph-line-height;
        margin-top: 20px;
        color: #5a5753;
        padding-right: 80px;
    }
}

.filters {
    margin: 0 80px 80px;
    background-color: #c2bfba;
    padding: 100px 50px;

    &__title {
        text-align: center;
        text-transform: uppercase;
        color: #fff;
        font-size: 4.375rem;
        font-weight: $font-light;
        margin-bottom: 20px;
    }

    &__holder {
        display: flex;
        flex-wrap: wrap;
    }

    &__item {
        flex-basis: 25%;
        padding: 0 20px;
        min-width: 200px;
        margin-top: 50px;

        &-label {
            color: #fff;
            cursor: pointer;
            display: block;
            font-size: 1.125rem;
            text-transform: uppercase; 
            margin-bottom: 10px;
        }

        &-select {
            width: 100%;
            background: transparent;
            border: 2px solid #8d8a85;
            color: #fff;
            cursor: pointer;
            padding: 20px 15px;         
            font-size: 1.375rem;  
            text-transform: uppercase;

            option {
                color: #8d8a85;
            }
        }
    }

    &__buttons {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 70px;
    }

    &__buttons-item {
        border: 2px solid #fff;
        background: #c2bfba;
        color: #fff;
        cursor: pointer;
        font-size: 1.5rem;
        min-width: 250px;
        margin: 15px 35px;
        padding: 15px 20px;
        line-height: 1;
        text-transform: uppercase;
    }
}

.nav-years {
    margin-top: 80px;

    &__wrapper {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: center;
    }

    &__item {
        margin: 10px 25px;
        font-size: 2.25rem;
        font-weight: $font-light;
        color: #ada9a2;

        &.active {
            color: #274451;
        }
    }
}

.gallery {
    padding: 40px;
    display: flex;
    flex-wrap: wrap;

    &__image {
        padding: 40px;
        flex-basis: 50%;

        a {
            display: block;
            overflow: hidden;
            @include darken-image();
        }
        
        img {
            width: 100%;
            display: block;
            transition: all .4s ease-in-out;
        }
    }

    &__caption {
        position: absolute;
        bottom: 85px;
        left: 40px;
        right: 40px;
        width: calc(100% - 80px);
        padding: 0 10px;
        text-align: center;
        font-size: 1.5rem;
        font-weight: $font-bold;
        color: #fff;
        z-index: 1;

        &::first-letter {
            text-transform: uppercase;
        }
    }
}

.google-map {
    margin-top: 80px;
}

.contacts {
    padding: 100px 0;

    &__wrapper {
        padding: 0 60px;
    }

    &__title {
        font-size: 3.75rem;
        font-weight: $font-light;
        color: #ada9a3;
        text-align: center;
    }

    &__form {
        margin-top: 100px;
    }
}

.form {

    &__row {
        margin-top: 45px;
        font-family: $font-base;
        font-size: 0.9375rem;

        &--two-columns {
            display: flex;
            justify-content: space-between;
        }

        &--center {
            align-items: center;
        }

        &--no-margin {
            margin: 0;
        }

        &--buttons {
            padding: 0 15px;
            text-align: center;

            .btn {
                margin-top: 100px;
            }
        }
    }

    &__col {
        padding: 0 15px;
        display: flex;
        align-items: flex-end;

        &-50 { 
            width: 50%;
        }

        &--textarea {
            display: block;

            .form__label {
                margin-bottom: 15px;
            }
        }

        &--no-padding {
            padding: 0;
        }
    }

    &__label {
        color: #898580;
        white-space: nowrap;
        text-transform: uppercase;
        margin-right: 10px;
        display: inline-block;
    }

    &__input, &__textarea {
        display: block;
        width: 100%;
        border-radius: 0;
        color: #736e67;

        &::placeholder {
            color: #736e67;
        }
    }

    &__input {
        padding: 10px 15px;
        font-size: 1.625rem;
        border: none;
        border-bottom: 2px solid #d0cbc5;
    }

    &__textarea {
        padding: 30px;
        min-height: 240px;
        font-size: 1.125rem;
        resize: vertical;
        border: 2px solid #d0cbc5;
    }

    &__checkbox {
        display: none;

        & + .form__label {
            position: relative;
            padding-left: 45px;
            cursor: pointer;
            display: flex;
            align-items: center;
            min-height: 30px;
            margin-left: 55px;
            
            &:before {
                position: absolute;
                content: '';
                left: 0;
                width: 25px;
                height: 25px;
                border: 2px solid #d0d4d8;
            }

            &:after {
                position: absolute;
                content: '\2714';
                left: 8px;
                top: 7px;
                display: none;
            }
        }   

        &:checked + .form__label:after {
            display: block;
        }
    }
}

.btn {
    padding: 20px 90px;
    font-family: $font-base;
    font-size: 32px;;
    text-transform: uppercase;
    display: inline-block;
    border: 2px solid #ada9a3;
    background: none;
    cursor: pointer;
    outline: none;
    color: #ada9a3;
    min-width: 170px;
    border-radius: 0;
    -webkit-appearance: none;
    margin: 0 auto;
    line-height: 1;
}

.pdp-characteristics {
    padding: 0 80px;

    &__holder {
        background-color: #c2bfba;
        padding: 45px 0;
        text-align: center;
    }
    
    &__btn {
        font-size: 1.125rem;
        text-transform: uppercase;
        border: 2px solid #fff;
        color: #fff;
        padding: 15px 80px;
    }

    &__title {
        font-size: 3.75rem;
        font-weight: $font-light;
        color: #274451;
        text-transform: uppercase;
        margin-top: 70px;
    }

    &__price {
        font-size: 2.25rem;
        color: #fff;
        margin-top: 30px;
    }

    &__table {
        width: 100%;
        border-collapse: collapse;
        text-align: left;
        margin-top: 60px;

        tr {
            position: relative;
            border-top: 2px solid #b3afa9;
        }

        td {
            padding: 15px 15px 35px 15px;
        }
    }

    &__key {
        text-transform: uppercase;
        color: #274451;
    }

    &__value {
        font-size: 1.875rem;
        text-transform: uppercase;
        color: #fff;
    }
}

.pdp-description {
    margin-top: 70px;

    p {
        margin-bottom: 25px;
        margin-top: 25px;
        font-size: 1.125rem;
        line-height: 1.66;;
    }
}

.pdp-gallery {
    margin-top: 100px;

    &__holder {
        margin-top: 55px;
    }
}

.pdp-text {
    margin-top: 100px;

    h1,h2,h3,h4,h5,h6 {
        color: #274451;
        font-size: 2.375rem;
        margin-bottom: 10px;
    }

    p {
        font-size: 1.125rem;
        line-height: 1.66;
        margin-bottom: 55px;
    }
}

.pdp-carousel {
    margin-top: 140px;

    .slick-dots {
        margin-top: 30px;
    }

    .slick-arrow {
        display: none !important;
    }
}

.pdp-title {
    font-size: 4.375rem;
    font-weight: $font-light;
    color: #ada9a3;
    display: flex;
    align-items: center;
    justify-content: center;

    &__icon {
        fill: #ada9a3;
        width: 40px;
        height: 40px;
        margin: 0 40px;
    }
}

.files-block {
    margin: 20px 0;
    padding-inline-start: 60px;

    ul {
        list-style-type: circle;
        padding-inline-start: 25px;
    }

    ol {
        list-style-type: upper-roman;
    }

    li {
        margin-top: 20px;
    }
}

.inner-info {
    margin-top: 45px;

    &__btn {
        width: 100%;
        max-width: 300px;
        margin-left: auto;
        margin-right: auto;
        display: block;
        padding: 15px;
        border: 2px solid #c2bfba;
        color: #736f69;
        text-transform: uppercase;
        text-align: center;
        font-size: 1.125rem;
    }

    &__title {
        font-size: 3.75rem;
        font-weight: $font-light;
        color: #274451;
        text-transform: uppercase;
        text-align: center;
        margin-top: 55px;
        margin-bottom: 0;
    }

    &__price {
        text-align: center;
        font-size: 2.25rem;
        color: #ada9a3;
        margin-top: 10px;
    }

    &__date {
        text-align: center;
        font-size: 30px;
        font-weight: $font-bold;
        color: #274451;
        margin-top: 90px;
    }

    .pdp-description {
        margin-top: 55px;
    }
}




@supports not (margin-inline-start: auto) {
    .header__top-nav {
        margin-left: auto;

        &--language {
            margin-left: 35px;
            margin-right: auto;
        }
    }

    .header__menu-close {
        margin-left: auto;
    }

    .promotions {
        &__link {
            margin-right: 25px;
        }

        &__seemore-icon {
            margin-right: 10px;
        }
    }

    .main-nav__expand {
        margin-left: 15px;
    }

    .services {
        &__price {
            margin-right: 15px;
        }

        &__link {
            margin-right: 90px;
        }
    }

    .blog__link {
        margin-right: 25px;
    }

    .hamburger-icon {
        margin-left: 25px;
    }
}

[dir="rtl"] {
    .tp-mask-wrap {
        left: 50%;
    }

    .header {
        &__menu {
            background: linear-gradient(to left, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 55%, #c2bfba 55%, #c2bfba 100%);
        }
    }

    .footer__gotop {
        right: auto;
        left: 35px;

        @media (max-width: map-get($grid-breakpoints, md)) {
            left: 10px;
        }
    }

    .breadcrumbs__item:after {
        right: auto;
        left: -4px;
    }

    @supports not (margin-inline-start: auto) {
        .header__top-nav {
            margin-right: auto;
    
            &--language {
                margin-right: 35px;
                margin-left: auto;
            }
        }
    
        .header__menu-close {
            margin-right: auto;
        }
    
        .promotions {
            &__link {
                margin-left: 25px;
            }
    
            &__seemore-icon {
                margin-left: 10px;
            }
        }
    
        .main-nav__expand {
            margin-right: 15px;
        }
    
        .services {
            &__price {
                margin-left: 15px;
            }
    
            &__link {
                margin-left: 90px;
            }
        }
    
        .blog__link {
            margin-left: 25px;
        }
    
        .hamburger-icon {
            margin-right: 25px;
        }
    }
}